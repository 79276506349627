document.addEventListener('alpine:init', () => {
    Alpine.data('attachments', (files, storeRoute, updateRoute, destroyRoute) => ({
        loading: false,
        errors: [],
        files,
        updateRoute,
        destroyRoute,

        upload(file) {
            const fd = new FormData();
            fd.append('file', file)

            axios({
                method: 'post',
                url: storeRoute,
                data: fd,
                headers: {'Content-Type': 'multipart/form-data'},
            })
                .then(r => this.files.push(r.data.data))
                .catch(e => console.log(e))
        }
    }));

    Alpine.data('attachment', (file) => ({
        editing: false,
        loading: false,
        file: file,
        newFilename: file.basename,

        editFilename() {
            this.editing = true;

            this.$nextTick(() => {
                this.$refs.input.focus();
                this.$refs.input.selectionStart = this.$refs.input.selectionEnd = 1000;
            })
        },

        updateFilename() {
            let route = this.updateRoute;
            route = route.replace(':id', this.file.id);

            this.loading = true;

            axios.patch(route, { filename: this.newFilename })
                .then(() => {
                    this.file.name = this.newFilename + '.' + this.file.ext;
                    this.editing = false;
                })
                .finally(() => this.loading = false);
        },

        deleteDocument() {
            let route = this.destroyRoute;
            route = route.replace(':id', this.file.id);

            const index = this.files.findIndex(att => att.id === this.file.id);
            axios.delete(route).then(() => this.files.splice(index, 1));
        },

        cancelEdit() {
            this.editing = false;
            this.newFilename = this.file.basename;
        }
    }));
});
