export function fireEvent(name, target, detail = {}, bubbles = true) {
    if (typeof target === 'string') {
        target = document.getElementById(target);
    }

    target.dispatchEvent(new CustomEvent(name, {
        bubbles,
        detail
    }))
}

export function blockScroll(block) {
    const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth

    if (block) {
        document.body.style.overflowY = "hidden"
        document.body.style.paddingRight = scrollBarWidth + "px"
    }
    else {
        document.body.style.overflowY = "auto"
        document.body.style.paddingRight = "0"
    }
}
