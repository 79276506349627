import './bootstrap';
import 'fslightbox';

import.meta.glob([
    '../images/**',
    '../fonts/**',
]);

window.getRoute = function(name, id) {
    let url = document.querySelector(`meta[name=${name}]`).content;
    url = url.replace(":id", id);

    return url;
}

import {fireEvent, blockScroll} from "./utils";
import './modal';
import './form';
import './offcanvas';
import './attachments';

window.fireEvent = fireEvent;
window.blockScroll = blockScroll;

import Alpine from 'alpinejs';
// import mask from '@alpinejs/mask'

window.Alpine = Alpine;

// Alpine.plugin(mask);
Alpine.start();
