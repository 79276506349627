document.addEventListener('alpine:init', () => {
    Alpine.data('form', () => ({
        errors: [],
        flash: false,
        status: null,
        loading: false,
        timeout: null,

        submit() {
            this.loading = true;

            axios
                .post(this.$root.action, new FormData(this.$root), {
                    headers: {'Content-Type': 'multipart/form-data'}
                })
                .then(r => {
                    this.saved(r.data);
                    this.errors = [];
                })
                .catch(err => {
                    this.errors = err.response.data.errors ?? [];
                    this.error(err.response.data.message ?? 'Error');
                })
                .finally(() => this.loading = false);
        },

        saved(data) {
            if (this.$refs.status) {
                this.$refs.status.innerText = 'Saved';
            }

            this.status = true;
            this.flash = true;
            if (this.timeout) {
                clearTimeout(this.timeout)
            }
            this.timeout = setTimeout(() => this.flash = false, 2000);
            this.$dispatch('saved', data)
        },

        error(message) {
            this.$refs.status.innerText = message ?? 'Error';
            this.status = false;
            this.flash = true;
            if (this.timeout) {
                clearTimeout(this.timeout)
            }
            this.timeout = setTimeout(() => this.flash = false, 2000);
        }
    }));
});
