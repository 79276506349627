document.addEventListener('alpine:init', () => {
    Alpine.data('modal', (show, hasFocusable) => ({
        show,
        hasFocusable,

        init() {
            this.$watch('show', value => {
                if (value) {
                    blockScroll(true);
                    if (this.hasFocusable) {
                        setTimeout(() => this.firstFocusable().focus(), 100);
                    }
                } else {
                    setTimeout(() => blockScroll(false), 200)
                }
            });
        },

        focusables() {
            // All focusable element types...
            let selector = "a, button, input:not([type='hidden']), textarea, select, details, [tabindex]:not([tabindex='-1'])"
            return [...this.$root.querySelectorAll(selector)]
                // All non-disabled elements...
                .filter(el => !el.hasAttribute('disabled') && el.getAttribute('tabindex') !== '-1')
        },

        firstFocusable() {
            return this.focusables()[0]
        },

        lastFocusable() {
            return this.focusables().slice(-1)[0]
        },

        nextFocusable() {
            return this.focusables()[this.nextFocusableIndex()] || this.firstFocusable()
        },

        prevFocusable() {
            return this.focusables()[this.prevFocusableIndex()] || this.lastFocusable()
        },

        nextFocusableIndex() {
            return (this.focusables().indexOf(document.activeElement) + 1) % (this.focusables().length + 1)
        },

        prevFocusableIndex() {
            return Math.max(0, this.focusables().indexOf(document.activeElement)) - 1
        },
    }));
});
